<template>
  <div>
    <NavigationBar titel="Konfiguration"></NavigationBar>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "my-organization-config",
  components: {
    NavigationBar,
  },
  data() {
    return {};
  },
};
</script>
